import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import logoImage from '../data/logo.png'; // logo film solar
import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios'; // Asegúrate de que axios esté importado

const Sidebar = ({ setIsAuthenticated,allowedRoutes  }) => { // Pasar la función para actualizar el estado de autenticación
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const navigate = useNavigate(); // Para redirigir al login después de cerrar sesión
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  // Función de logout
  const handleLogout = () => {
    // Remover los tokens del localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    // Remover el token de los headers de Axios
    delete axios.defaults.headers.common['Authorization'];

    // Cambiar el estado de autenticación
    setIsAuthenticated(false);

    // Redirigir al login
    navigate('/login');
  };


  const activeLink = 'flex items-center gap-3 pl-4 pt-3 pb-2.5 rounded-lg text-white m-2 text-lg md:text-md';
  const normalLink = 'flex items-center gap-3 pl-4 pt-3 pb-2.5 rounded-lg text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2 text-lg md:text-md';
  


  
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mr-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <img src={logoImage} alt="FilmSolar Logo" className='h-12 w-auto md:h-14' /> {/* Ajusta el tamaño según necesites */}
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
            <button
              onClick={() => setActiveMenu(false)} // 🔥 Siempre cierra el menú
              className="absolute top-4 right-4 text-gray-800 text-4xl p-3 rounded-full hover:bg-gray-200 transition duration-200 md:hidden flex items-center justify-center"
            >
              <MdOutlineCancel className="text-gray-700 text-5xl" /> {/* 🔥 Mejora la apariencia del icono */}
            </button>
            </TooltipComponent>
          </div>
          <div>
            {links.map((item) => (
              <div key={item.title}>
                {item.links
                  .filter((link) => allowedRoutes.includes(link.name.toLowerCase())) // Compara nombres en minúsculas
                  .map((link) =>
                    link.action === 'logout' ? (
                      <div
                        key={link.name}
                        onClick={handleLogout}
                        className={normalLink}
                        style={{ cursor: 'pointer' }}
                      >
                        {link.icon}
                        <span className="capitalize">{link.name.replace('_', ' ')}</span>
                      </div>
                    ) : (
                      <NavLink
                        to={`/${link.name.toLowerCase()}`} // Rutas en minúsculas para evitar conflictos
                        key={link.name}
                        onClick={() => setActiveMenu(false)}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : '',
                        })}
                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                      >
                        <span className="text-xl md:text-lg">{link.icon}</span> 
                        <span className="capitalize font-semibold">{link.name.replace('_', ' ')}</span> 
                      </NavLink>
                    )
                  )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
