import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Toolbar, Inject, Edit, Sort, Filter, Search } from '@syncfusion/ej2-react-grids';
import { Header, DetalleClientes, AgregarCliente } from '../components';
import axios from 'axios';

const Clientes = () => {
  const [clientesData, setClientesData] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [showAddUser, setShowAddUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: false, allowEditing: true };

  // Función para obtener los datos de clientes desde la API
  const fetchClientesData = async () => {
    try {
      setLoading(true);
      setError(null); // Reinicia cualquier error previo

      const token = localStorage.getItem('access_token'); 
      if (!token) {
        setError('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        setLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clientes/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setClientesData(response.data);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
      setError('Ocurrió un error al cargar los clientes.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientesData(); // Llamar a la API al cargar el componente
  }, []);

  const handleRowClick = (args) => {
    setSelectedCliente(args.data);  // 'args.data' contiene los datos de la fila seleccionada
  };

  const handleNewUserClick = () => {
    setShowAddUser(true);
  };

  const handleClienteAdded = () => {
    fetchClientesData(); // Volver a cargar los clientes cuando se agrega uno nuevo
  };

  if (loading) {
    return <p>Cargando...</p>; 
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Clientes" />

      <div className="flex justify-between items-center mb-4">
        <div className="ml-auto">
          <button
            type="button"
            onClick={handleNewUserClick}
            style={{
              backgroundColor: '#1fb519',
              color: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '8px 16px',
              borderRadius: '8px',
              fontWeight: 'lighter',
              textTransform: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            + Agregar Cliente
          </button>
        </div>
      </div>

      <GridComponent
        dataSource={clientesData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5, pageSize: 15 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
        sortSettings={{ columns: [{ field: 'id', direction: 'Descending' }] }} // Orden descendente por defecto
        rowSelected={handleRowClick}  // Cambiado a 'rowSelected' para clic simple
      >
        <ColumnsDirective>
          <ColumnDirective field='id' headerText='ID' width='100' textAlign='Center' />
          <ColumnDirective field='nombre' headerText='Nombre' width='150' textAlign='Left' />
          <ColumnDirective field='apellido' headerText='Apellido' width='150' textAlign='Left' />
          <ColumnDirective field='email' headerText='Email' width='200' textAlign='Left' />
          <ColumnDirective field='whatsapp' headerText='WhatsApp' width='150' textAlign='Left' />
          <ColumnDirective field='region' headerText='Región' width='150' textAlign='Left' />
          <ColumnDirective field='comuna' headerText='Comuna' width='150' textAlign='Left' />
          <ColumnDirective field='direccion' headerText='Dirección' width='200' textAlign='Left' />
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search]} />
      </GridComponent>

      {selectedCliente && (
        <DetalleClientes 
          cliente={selectedCliente} 
          onClose={() => setSelectedCliente(null)} 
        />
      )}

      {showAddUser && (
        <AgregarCliente 
          onClose={() => setShowAddUser(false)} 
          onClienteAdded={handleClienteAdded} // Pasar la función para actualizar clientes
        />
      )}
    </div>
  );
};

export default Clientes;
