import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header, DetalleInstalacion } from '../components';
import { Tabs, Tab } from '@mui/material';

const Instalacion = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [allData, setAllData] = useState([]);  // Guarda todos los datos
  const [filteredData, setFilteredData] = useState([]);
  const [detalleInstalacionVisible, setDetalleInstalacionVisible] = useState(false);
  const [selectedInstalacion, setSelectedInstalacion] = useState(null);
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [selectedCotizacion, setSelectedCotizacion] = useState(null);

  // Función para obtener todas las instalaciones
  const fetchInstalaciones = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      } 

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      // Llamada a la API para obtener todas las instalaciones con los detalles ya relacionados
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/listar/`, config);
      const instalaciones = response.data;
      // Formatear los datos según las necesidades de tu aplicación
      const instalacionesConDetalles = instalaciones.map((instalacion) => {
        const orden = instalacion.orden || {};
        const cotizacion = orden.cotizacion || {};
        const solicitante = cotizacion.solicitante || {};

        return {
          id_instalacion: instalacion.id_instalacion,
          solicitante: solicitante.nombre_completo,  
          email: solicitante.email || '',
          fecha_instalacion: orden.fecha_instalacion,
          tipo_propiedad: cotizacion.tipo_propiedad,
          estado_instalacion: instalacion.estado_instalacion,
          direccion_instalacion: cotizacion.direccion_instalacion,
          instalacion,  // Guardar la instalación completa para el detalle

        };
      });
  
      // Guardar todos los datos en el estado
      setAllData(instalacionesConDetalles);
  
      // Aplicar filtro basado en la pestaña seleccionada
      filterData(instalacionesConDetalles, selectedTab);
    } catch (error) {
      console.error('Error al obtener las instalaciones:', error);
    }
  };

  useEffect(() => {
    fetchInstalaciones();  // Llamar a la función para obtener datos al montar el componente
  }, []);

  // Función para filtrar los datos basados en el tabIndex
  const filterData = (data, tabIndex) => {
    let newData = data || allData;  // Usa los datos proporcionados o los ya almacenados
    switch (tabIndex) {
      case 0:
        newData = newData.filter(item => item.estado_instalacion === 'pendiente');
        break;
      case 1:
        newData = newData.filter(item => item.estado_instalacion === 'realizada');
        break;
      case 2:
        newData = newData.filter(item => item.estado_instalacion === 'visita_terreno');
        break;
      default:
        newData = allData;
    }
    setFilteredData(newData);
  };
  const formattedData = useMemo(() => {
    return filteredData.map((item) => ({
      ...item,
      fecha_instalacion: item.fecha_instalacion
        ? new Date(item.fecha_instalacion).toLocaleDateString('es-CL', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).replace(/-/g, '/')
        : '',
      hora_instalacion: item.fecha_instalacion
        ? new Date(item.fecha_instalacion).toLocaleTimeString('es-CL', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })
        : '',
    }));
  }, [filteredData]);

  const refreshInstalaciones = () => {
    fetchInstalaciones();
  };

  // Llamar a filterData cuando se cambia la pestaña
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    filterData(allData, newValue);  // Filtrar datos al cambiar de pestaña
  };

  const handleRowClick = (args) => {
    const rowData = args.data;  // 'args.data' contiene los datos de la fila seleccionada
    const { instalacion } = rowData || {};
    if (!instalacion) {
      console.error('Faltan datos de instalación, orden o cotización');
      return;
    }
  
    setSelectedInstalacion(instalacion);
    setDetalleInstalacionVisible(true);
  };

  const TabWithIcon = ({ color, label }) => (
    <div
      style={{
        display: 'inline-block',
        backgroundColor: color,
        borderRadius: '12px',
        padding: '2px 8px',
        fontSize: '12px', // Tamaño más pequeño
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        minWidth: '60px',
      }}
    >
      {label}
    </div>
  );

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Mis instalaciones" />

      <div className="flex justify-between items-center">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800',
              },
              '&.Mui-selected': {
                color: '#FFa800',
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800',
            },
          }}
        >
          <Tab label={<TabWithIcon color="#FEC90F" label="Pendiente" />} />
          <Tab label={<TabWithIcon color="#8BE78B" label="Realizadas" />} />
          <Tab label="Visita Terreno" />
        </Tabs>
      </div>

      {detalleInstalacionVisible && (
        <DetalleInstalacion
          onClose={() => {
            setDetalleInstalacionVisible(false);
            refreshInstalaciones();
          }}
          instalacion={selectedInstalacion}
          orden={selectedOrden}
          cotizacion={selectedCotizacion}
        />
      )}
      {
        console.error(formattedData)
      }
      <GridComponent
        dataSource={formattedData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5, pageSize: 15 }}
        selectionSettings={{ persistSelection: true, type: 'Single' }}  // Selección de filas individuales
        toolbar={[]}
        editSettings={{ allowEditing: false, allowDeleting: false }}  // Desactivar la edición
        allowSorting
        sortSettings={{ columns: [{ field: 'id_instalacion', direction: 'Descending' }] }}  // Orden descendente por ID Instalación
        className="mt-4"
        //recordDoubleClick={handleRowDoubleClick}
        rowSelected={handleRowClick}  // Cambiado a 'rowSelected' para clic simple
      >
        <ColumnsDirective>
          <ColumnDirective field="id_instalacion" headerText="ID" width="120" textAlign="Center" />
          <ColumnDirective field="solicitante" headerText="Solicitante" width="200" textAlign="Center" />
          <ColumnDirective 
            field="fecha_instalacion" 
            headerText="Fecha Instalación" 
            width="150" 
            textAlign="Center"
            allowSorting={true} 
          />
          <ColumnDirective 
            field="hora_instalacion" 
            headerText="Hora Instalación" 
            width="150" 
            textAlign="Center"
            allowSorting={true} 
          />
          <ColumnDirective field="tipo_propiedad" headerText="Propiedad" width="150" textAlign="Center" />
          <ColumnDirective field="estado_instalacion" headerText="Estado" width="150" textAlign="Center" />
          <ColumnDirective field="direccion_instalacion" headerText="Dirección Instalación" width="200" textAlign="Center" />
        </ColumnsDirective>

        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Instalacion;
