import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiOutlineMenu } from 'react-icons/ai';
import { Footer, Sidebar, ThemeSettings, Cotizacion } from './components';
import { Dashboard, Ordenes, Stock, Clientes, Finanzas, Cotizaciones, Seguridad, Instalacion, Agenda, Login } from './pages';
import './App.css';
import { useStateContext } from './contexts/ContextProvider';
import axios from 'axios'; // Para hacer la petición
import logoImage from './data/logo.png'



const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    cotizacionVisible,
    setActiveMenu,
  } = useStateContext();

  const [userInfo, setUserInfo] = useState(null); // Estado para guardar la información del usuario
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
 

  const userRoutes = {
    sebastian: ['dashboard', 'cotizaciones', 'ordenes', 'agenda', 'instalacion', 'stock', 'clientes', 'finanzas', 'seguridad','cerrar_sesion'],
    filmsolar: ['dashboard', 'cotizaciones', 'ordenes', 'agenda', 'instalacion', 'stock', 'clientes', 'finanzas', 'seguridad','cerrar_sesion'],
    Lukitas: ['agenda', 'instalacion','cerrar_sesion'],
    Vendedor: ['cotizaciones', 'ordenes', 'agenda', 'instalacion','cerrar_sesion'],
  };


  const allowedRoutes = userInfo ? userRoutes[userInfo.username] || [] : [];
  useEffect(() => {
    const fetchUserInfo = async () => {
      setIsLoading(true); // Comienza la carga
      const token = localStorage.getItem('access_token'); // Obtén el token almacenado
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false); // Finaliza la carga si no hay token
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/user-info/`, {
          headers: {
            Authorization: `Bearer ${token}`, // Envía el token como encabezado
          },
        });
        setUserInfo(response.data); // Guarda la información en el estado
        localStorage.setItem('username', response.data.username); // Guarda el username en localStorage
        setIsAuthenticated(true);

      } catch (error) {
        console.error('Error al obtener la información del usuario:', error);
        localStorage.removeItem('access_token'); // Limpia el token si hay error
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Finaliza la carga
    };

    fetchUserInfo();
    
  }, []);



  useEffect(() => {
    // Recuperar el estado de autenticación desde el localStorage cuando la aplicación se carga/refresca
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsAuthenticated(true); // Si hay token, el usuario está autenticado
    } else {
      setIsAuthenticated(false); // Si no hay token, redirigir al login
    }

    // Configurar el tema al cargar la aplicación
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  if (isLoading) {
    return <div>Cargando...</div>;
  }
  return (
    
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      
      {/* SOLO un BrowserRouter envolviendo toda la aplicación */}
      <BrowserRouter>
        {isAuthenticated ? ( // Si el usuario está autenticado, mostrar la app
          <div className="flex relative dark:bg-main-dark-bg overflow-x-hidden">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>


            <header
              className={`fixed top-0 w-full bg-white shadow-md z-50 grid grid-cols-3 items-center px-4 py-2 transition-all duration-300 ${
                activeMenu ? 'md:ml-72' : 'ml-0'
              }`}
            >
              {/* Menú Hamburguesa (Columna 1 - Izquierda) */}
              <button
                type="button"
                onClick={handleActiveMenu}
                style={{ color: currentColor }}
                className="text-2xl hover:bg-light-gray rounded-full w-10 h-10 flex items-center justify-center"
              >
                
                <AiOutlineMenu />
              </button>

              {/* Espaciador Central (Columna 2 - Vacía para centrar el contenido) */}
              <div className="flex-grow"></div>

              {/* Logo (Columna 3 - Derecha) */}
              <a href="/" className="flex items-center justify-self-end">
                <img src={logoImage} alt="filmsolar" className="h-14 w-auto" />
              </a>
            </header>

            {activeMenu ? (
              <div className={`fixed left-0 top-0 h-full dark:bg-secondary-dark-bg bg-white transition-all duration-300
                ${activeMenu ? "w-full md:w-64 lg:w-72 z-50" : "w-0 z-[-1]"}`}
              >
                <Sidebar setIsAuthenticated={setIsAuthenticated} allowedRoutes={allowedRoutes} /> {/* Pasar la función */}
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar setIsAuthenticated={setIsAuthenticated} allowedRoutes={allowedRoutes} />
              </div>
            )}

            <div
              className={`transition-all duration-300 ${
                activeMenu ? 'md:ml-72' : 'ml-0'
              } dark:bg-main-dark-bg bg-gray-200 min-h-screen w-full`}
              style={{ paddingTop: '64px' }}
            >

              {themeSettings && <ThemeSettings />}
              {cotizacionVisible && <Cotizacion />}

              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/cotizaciones" element={<Cotizaciones />} />
                <Route path="/ordenes" element={<Ordenes />} />
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/instalacion" element={<Instalacion />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/clientes" element={<Clientes />} />
                <Route path="/finanzas" element={<Finanzas />} />
                <Route path="/seguridad" element={<Seguridad />} />
                {/* Redirigir de login a dashboard si ya está autenticado */}
                <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
              </Routes>
              <Footer />
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} /> {/* Redirigir a login si no está autenticado */}
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
